<template>
  <v-container>
    <div class="actions-init" v-if="!selectedAction">
      <div>
        <v-btn
          class="ma-2"
          color="primary"
          @click="restoreMissingPlaySubSelected()"
          disabled
        >
          Restore Missing Google Play Sub
        </v-btn>
      </div>
      <v-row>
        <v-col col="12">
          <v-btn class="ma-2" color="primary" @click="addNewReseller">
            Add new Reseller
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="actions-controls" v-if="selectedAction != null">
      <div class="usersub-section" v-if="selectedAction == 'missing-play-sub'">
        <v-text-field
          label="Enter business ID "
          color="primary"
          type="text"
          v-model="missingPlaySub.businessId"
          prepend-icon="mdi-email"
        />
        <v-text-field
          label="Enter purchase token "
          color="primary"
          type="text"
          v-model="missingPlaySub.purchaseToken"
          prepend-icon="mdi-key"
        />
        <v-btn
          class="ma-2"
          disabled
          color="primary"
          @click="restorePlaySubSubmit()"
        >
          Restore
        </v-btn>
      </div>
      <div class="reseller-section" v-if="selectedAction == 'add-new-reseller'">
        <v-text-field
          label="Name "
          color="primary"
          type="text"
          v-model="reseller.name"
          prepend-icon="mdi-account"
        />
        <v-text-field
          label="Email "
          color="primary"
          type="text"
          v-model="reseller.email"
          prepend-icon="mdi-email"
        />
        <v-text-field
          label="Address "
          color="primary"
          type="text"
          v-model="reseller.address"
          prepend-icon="mdi-home-city"
        />
        <v-text-field
          label="Phone Number "
          color="primary"
          type="text"
          v-model="reseller.phoneNumber"
          prepend-icon="mdi-phone"
        />
        <v-text-field
          label="Whatsapp Number "
          color="primary"
          type="text"
          v-model="reseller.whatsappNumber"
          prepend-icon="mdi-whatsapp"
        />
        <v-text-field
          label="Short Code "
          color="primary"
          type="text"
          v-model="reseller.code"
          prepend-icon="mdi-code-greater-than"
        />
        <v-text-field
          label="Country "
          color="primary"
          type="text"
          v-model="reseller.country"
          prepend-icon="mdi-origin"
        />
        <v-text-field
          label="Recharge Tokens "
          color="primary"
          type="number"
          v-model="reseller.rechargeTokensLeft"
          prepend-icon="mdi-account-cash"
        />

        <v-btn class="ma-2" color="primary" @click="createReseller()">
          Create Reseller
        </v-btn>
      </div>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { restorePlaySub } from "../../services/subscription/utilities";
import resellerService from "../../services/reseller";

function copyToClipboard(text) {
  var dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

export default {
  data() {
    return {
      usersub: false,
      businesssub: false,
      pricing: false,
      selectedAction: null,
      missingPlaySub: {
        businessId: null,
        purchaseToken: null,
      },
      reseller: {
        name: "Test",
        address: "Test",
        code: "TEST",
        country: "Test",
        email: "test@test.test",
        rechargeTokensLeft: 0,
        whatsappNumber: "+919999999999",
        phoneNumber: "+919999999999",
      },
    };
  },
  computed: {
    ...mapGetters({
      activeAccount: "realm/active",
      businessFetchWaiting: "business/isFetchWaiting",
      businessFetchError: "business/fetchError",
      businessList: "business/list",
    }),
    businessesLinked() {
      return this.businessList.length;
    },
    totalSalesMade() {
      const businessWithSales = this.businessList.filter(
        (x) => x.activePlanId != null
      );
      return businessWithSales.length;
    },
  },
  methods: {
    addNewReseller() {
      this.selectedAction = "add-new-reseller";
    },
    restoreMissingPlaySubSelected() {
      this.selectedAction = "missing-play-sub";
    },
    getFormattedDate(date) {
      return date ? moment(date).format("ll") : "-";
    },
    sharePartnerLink() {
      // Get the business open link here
      const partnerLink = this.activeAccount.shareLink;
      if (partnerLink) {
        if (navigator.share) {
          navigator
            .share({
              title: "Zobaze POS",
              text: "Go digital with Zobaze POS.",
              url: partnerLink,
            })
            .then(() => console.log("Successful share"))
            .catch((error) => console.log("Error sharing", error));
        } else {
          copyToClipboard(partnerLink);
          this.$notifier.showMessage({
            content: "Link copied to clipboard",
          });
        }
      } else {
        this.$notifier.showMessage({
          content:
            "Share code has not been created for this account. Please contact us via chat or email to create it",
        });
      }
    },
    async restorePlaySubSubmit() {
      await restorePlaySub(
        this.activeAccount.id,
        this.missingPlaySub.businessId,
        this.missingPlaySub.purchaseToken
      );
    },
    async createReseller() {
      try {
        const reseller = this.reseller;
        let errorMessage = null;
        if (reseller.name == null || reseller.name.length < 2) {
          this.$notifier.showMessage({
            content: "Reseller name should be greater than 2 characters",
          });
        } else if (reseller.code == null || reseller.code.length != 4) {
          errorMessage = "Reseller code should be of 4 characters in length";
        }
        if (errorMessage) {
          this.$notifier.showMessage({ content: errorMessage });
        }
        const response = await resellerService.createNewReseller(
          this.activeAccount.id,
          reseller
        );
        this.$notifier.showMessage({
          content:
            "Created successfully. Password is " +
            response.password +
            " Also printed to console",
        });
        console.log(response.password)
      } catch (e) {
        console.error(e);
        this.$notifier.showMessage({ content: e.message });
      }
    },
  },
};
</script>

<style>
.home {
  min-height: 100%;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.centered-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
</style>
